import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import StatisticsCard from 'components/reusable/StatisticsCard';
import CreditScore from 'components/reusable/graphs/CreditScoreGraph';

import { ReactComponent as RatingIcon } from 'assets/img/icons/rating.svg';
import useFormatDate from 'hooks/format/useFormatDate';

import Icon from '@ant-design/icons';
import useIsUiHidden from '../../../hooks/dom/useIsUiHidden';
import Statistic from 'components/reusable/Statistic';

const Ratings = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { summary } = useSelector(state => state.professionalReport);
  const isUiHidden = useIsUiHidden();

  const RatingClassType = {
    _0: 0,
    _1: 1,
    _2: 2,
    _3: 3,
    _4: 4,
    _5: 5,
    _6: 6,
    _7: 7,
    _8: 8,
    _9: 9,
    _10: 10,
    _99: 99,
  };

  const defaultClass = RatingClassType[summary?.defaultScore?.ratingClass];
  const bankruptcyClass = RatingClassType[summary?.bankruptcyScore?.ratingClass];

  return (
    <div className="space-vertical-sm">
      <Row gutter={isUiHidden ? [3] : [16, 16]} className="ant-row-pdf-gap">
        {summary?.defaultScore?.pd ? (
          <Col sm={isUiHidden ? 12 : 24} md={isUiHidden ? 12 : 24} lg={12}>
            <StatisticsCard
              title={t('professionalReport.summary.defaultRating.title')}
              icon={<Icon component={RatingIcon} className="icon--gray" />}
              items={[
                {
                  number: `${t('global.class')} ${defaultClass}`,
                  text: t(`professionalReport.companyRatings.description.${defaultClass}`),
                },
                {
                  graph:
                    defaultClass === 0 || defaultClass === 99 ? (
                      <Statistic
                        description={`${
                          summary?.defaultScore?.ratingSubClass === '_99' ? '99' : summary?.defaultScore?.ratingSubClass
                        } - ${t(
                          `professionalReport.companyRatings.description.subClass.${summary?.defaultScore?.ratingSubClass}`
                        )}`}
                      />
                    ) : (
                      <CreditScore
                        id="default"
                        type="defaultRating"
                        value={defaultClass}
                        percentage={summary?.defaultScore?.pd}
                      />
                    ),
                },
                summary?.rating?.defaultExpertDate
                  ? {
                      number: formatDate(summary?.rating?.defaultExpertDate),
                      text: `${t('professionalReport.companyRatings.defaultRating.expertEvaluation')}`,
                    }
                  : {},
                !summary?.rating?.defaultExpertDate
                  ? {
                      number: null,
                      text:
                        !summary?.defaultScore?.pd && `${t('professionalReport.companyRatings.recommendMessage.text')}`,
                    }
                  : {},
              ]}
            />
          </Col>
        ) : (
          <Col sm={isUiHidden ? 12 : 24} md={isUiHidden ? 12 : 24} lg={12}>
            <StatisticsCard
              title={t('professionalReport.summary.defaultRating.title')}
              icon={<Icon component={RatingIcon} className="icon--gray" />}
              items={[
                {
                  number: defaultClass ?? '-',
                  text: summary?.defaultScore?.riskClassName,
                },
                summary?.defaultScore?.riskClassDescription
                  ? {
                      number: null,
                      text: summary?.defaultScore?.riskClassDescription,
                    }
                  : {},
              ]}
            />
          </Col>
        )}
        {summary?.bankruptcyScore?.pd ? (
          <Col sm={isUiHidden ? 12 : 24} md={isUiHidden ? 12 : 24} lg={12}>
            <StatisticsCard
              title={t('professionalReport.summary.bankruptcyRating.title')}
              icon={<Icon component={RatingIcon} className="icon--gray" />}
              items={[
                {
                  number: `${t('global.class')} ${bankruptcyClass}`,
                  text: t(`professionalReport.companyRatings.description.${bankruptcyClass}`),
                },
                {
                  graph:
                    bankruptcyClass === 0 || bankruptcyClass === 99 ? (
                      <Statistic
                        description={`${
                          summary?.bankruptcyScore?.ratingSubClass === '_99'
                            ? '99'
                            : summary?.defaultScore?.ratingSubClass
                        } - ${t(
                          `professionalReport.companyRatings.description.subClass.${summary?.bankruptcyScore?.ratingSubClass}`
                        )}`}
                      />
                    ) : (
                      <CreditScore
                        id="bankruptcy"
                        type="bankruptcyRating"
                        value={bankruptcyClass}
                        percentage={summary?.bankruptcyScore?.pd}
                      />
                    ),
                },
                summary?.rating?.bankruptcyExpertDate
                  ? {
                      number: formatDate(summary?.rating?.bankruptcyExpertDate),
                      text: `${t('professionalReport.companyRatings.bankruptcyRating.expertEvaluation')}`,
                    }
                  : {},
                !summary?.rating?.bankruptcyExpertDate
                  ? {
                      number: null,
                      text:
                        !summary?.bankruptcyScore?.pd &&
                        `${t('professionalReport.companyRatings.recommendMessage.text')}`,
                    }
                  : {},
              ]}
            />
          </Col>
        ) : (
          <Col sm={isUiHidden ? 12 : 24} md={isUiHidden ? 12 : 24} lg={12}>
            <StatisticsCard
              title={t('professionalReport.summary.bankruptcyRating.title')}
              icon={<Icon component={RatingIcon} className="icon--gray" />}
              items={[
                {
                  number: bankruptcyClass ?? '-',
                  text: summary?.bankruptcyScore?.riskClassName,
                },
                summary?.bankruptcyScore?.riskClassDescription
                  ? {
                      number: null,
                      text: summary?.bankruptcyScore?.riskClassDescription,
                    }
                  : {},
              ]}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Ratings;
