import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CompanyBasicInformation from 'components/reusable/report/cards/CompanyBasicInformation';
import CompanyManager from 'components/reusable/report/cards/CompanyManager';
import CompanyContactInformation from 'components/reusable/report/cards/CompanyContactInformation';
import AuthorizedCapital from 'components/reusable/report/cards/AuthorizedCapital';
import ReportBlockHeader from 'components/reusable/report/ReportBlockHeader';
import PressReleasesTable from 'components/reusable/report/tables/PressReleasesTable';
import CreditLimit from 'views/StartReport/BasicData/CreditLimit';
import Reports from 'views/StartReport/BasicData/Reports';
import Employees from 'views/StartReport/BasicData/Employees';
import Vehicles from 'views/StartReport/BasicData/Vehicles';
import Licences from 'views/StartReport/BasicData/Licences';
import UpdateContactInfo from 'views/StartReport/BasicData/UpdateContactInfo';
import Restructurizations from '../../../components/reusable/report/cards/Restructurizations';
import { basicInfo, pressReleasesInfo } from 'store/entities/startReport/selectors/basicDataSelectors';

import { ReactComponent as Response } from 'assets/img/icons/Response.svg';

import { Card } from 'antd';
import { useSelector } from 'react-redux';
import BoardMembers from './BoardMembers';
import { JadisInformationCard } from '../../../components/reusable/report/cards/JadisInformationCard';
import { ProcurementInformation } from '../../../components/reusable/report/cards/ProcurementInformation';
import BankruptcyInfo from '../../../components/reusable/report/cards/BankruptcyInfo';
import SocialSecurityContributions from '../../../components/reusable/report/cards/SocialSecurityContributions';
import { ActivitiesRestrictions } from '../../../components/reusable/report/cards/ActivitiesRestrictions';

const Basic = () => {
  const { t } = useTranslation();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const {
    basicInformation,
    pressReleases,
    authorizedCapital,
    activitiesRestrictions,
    activitiesRestrictionsHistory,
    restructurizations,
    bankruptcyInformation,
    boardMember,
    manager,
    contacts,
    licenses,
    statistics,
    jadisInformation,
    jadisInformationHistory,
    wonPublicProcurementTenders,
    wonPublicProcurementTendersContracts,
    wonPublicProcurementTendersContractsChanges,
    announcedPublicProcurementTenders,
    announcedPublicProcurementTendersContracts,
    announcedPublicProcurementTendersContractsChanges,
    socialInsuranceContributionLastYearsSummary,
    socialInsuranceContributions,
  } = useSelector(basicInfo);

  const { pressReleasesVersloZinios } = useSelector(pressReleasesInfo);

  return (
    <>
      <CompanyBasicInformation companyData={basicInformation} />
      <ActivitiesRestrictions
        activitiesRestrictions={activitiesRestrictions}
        activitiesRestrictionsHistory={activitiesRestrictionsHistory}
      />
      <CompanyManager managerData={manager} />
      {licenses?.active?.length > 0 && <Licences licences={licenses} />}
      <CompanyContactInformation contactData={contacts} setUpdateModalOpen={setUpdateModalOpen} />
      <UpdateContactInfo modalOpen={updateModalOpen} setModalOpen={setUpdateModalOpen} initialValues={contacts} />
      <AuthorizedCapital showHistorical={false} authorizedCapital={authorizedCapital} />
      <BankruptcyInfo bankruptcyInformation={bankruptcyInformation} />
      <Restructurizations restructurizations={restructurizations} />
      <BoardMembers boardMembersData={boardMember} />
      <JadisInformationCard jadisInformation={jadisInformation} jadisInformationHistory={jadisInformationHistory} />
      <Employees />
      <SocialSecurityContributions
        tableData={socialInsuranceContributionLastYearsSummary}
        chartData={socialInsuranceContributions}
      />
      <Vehicles />
      <ProcurementInformation
        wonPublicProcurementTenders={wonPublicProcurementTenders}
        wonPublicProcurementTendersContracts={wonPublicProcurementTendersContracts}
        wonPublicProcurementTendersContractsChanges={wonPublicProcurementTendersContractsChanges}
        announcedPublicProcurementTenders={announcedPublicProcurementTenders}
        announcedPublicProcurementTendersContracts={announcedPublicProcurementTendersContracts}
        announcedPublicProcurementTendersContractsChanges={announcedPublicProcurementTendersContractsChanges}
      />
      <ReportBlockHeader
        title={t('startReport.basic.press.title')}
        disclaimer={t('startReport.basic.press.disclaimer')}
        icon={<Response />}
      />
      <PressReleasesTable pressReleases={pressReleases} />
      <ReportBlockHeader
        title={t('startReport.basic.press.archive.title')}
        disclaimer={t('startReport.basic.press.disclaimer')}
      />
      {pressReleasesVersloZinios.length > 0 ? (
        <PressReleasesTable pressReleases={pressReleasesVersloZinios} type="versloZinios" />
      ) : (
        <Card className="card-transparent space-vertical-sm">{t('startReport.basic.press.noPressReleases')}</Card>
      )}
      <Reports statistics={statistics} />
      <CreditLimit />
    </>
  );
};

export default Basic;
