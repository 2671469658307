import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Checkbox, Col, Form, Radio, Row, Divider, Typography } from 'antd';

import {
  completeStep,
  unCompleteStep,
  setCompanyBuyerInformation,
  setPersonBuyerInformation,
  setBuyReportBuyerType,
  setBuyReportStep,
  setOpayData,
  setPage,
} from 'store/entities/companySearchResult';
import CompanyOrderForm from 'views/CompanySearchResult/components/BuyReport/CompanyOrderForm';
import IndividualOrderForm from 'views/CompanySearchResult/components/BuyReport/IndividualOrderForm';
import { buyReportData } from 'store/entities/companySearchResult/selectors/buyReportSelector';
import OrderActions from 'views/CompanySearchResult/components/BuyReport/OrderActions';
import {
  getOpayUriEncoded,
  getOrderIdFromCompanyBuyReport,
  getOrderIdFromPersonBuyReport,
} from 'store/entities/companySearchResult/thunks';
import { basicData } from 'store/entities/companySearchResult/selectors/unregisteredReportSelector';
import SingleResult from 'components/reusable/SingleResult';

import privacyPolicy from 'assets/pdf/reports-privacy-policy.pdf';
import privacyPolicyLt from 'assets/pdf/reports-privacy-policy-lt.pdf';
import terms from 'assets/pdf/report-conditions.pdf';
import termsLt from 'assets/pdf/report-conditions-lt.pdf';
import useWindowSize from 'hooks/dom/useWindowSize';
import { trackEvent } from '../../../../utils/general/amplitudeService';
import { showErrorNotification } from '../../../../utils/notification';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const OrderInformation = () => {
  const {
    buyerType,
    companyBuyerInformation,
    personBuyerInformation,
    productType,
    productPrice,
    selectedReportType,
    selectedReport,
    currencyType,
  } = useSelector(buyReportData);

  const [vatBillRequired, setVatBillRequired] = useState(
    companyBuyerInformation?.isTaxInvoiceRequired || personBuyerInformation?.isTaxInvoiceRequired
  );
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { width: windowWidth } = useWindowSize();
  const [form] = Form.useForm();

  const { companyId, report } = useSelector(state => state.companySearchResult);
  const { companyName, companyCode, status, address } = useSelector(basicData);

  const isCompany = buyerType === 'company';
  const getLanguageType = () => {
    switch (i18n.language) {
      case 'en-US':
        return 'Eng';
      case 'lt-LT':
      default:
        return 'Lit';
    }
  };

  const handleOnFinish = async values => {
    if (isCompany && values?.companyCode) {
      const symbolRegex = /[^0-9]/g;
      const hasSymbols = symbolRegex.test(values?.companyCode);
      if (hasSymbols) {
        showErrorNotification(t('companyResult.buyReport.handleOnFinish.hasSymbolsError'));
        trackEvent('Error', {
          errorType: `Input error`,
          errorMessage: t('companyResult.buyReport.handleOnFinish.hasSymbolsError'),
          inputValues: values,
        });
        return;
      }
    }
    try {
      const token = await executeRecaptcha('orderInformation');
      const buyerInformation = {
        ...values,
        createdDate: new Date().toISOString(),
        productType,
        productPrice,
        token,
        currencyType,
        searchCompanyCode: parseFloat(companyId),
        languageType: getLanguageType,
        ...(selectedReportType === 'financialStatement' && { financialReportId: selectedReport }),
      };
      const { id: orderId } = await dispatch(
        isCompany ? getOrderIdFromCompanyBuyReport(buyerInformation) : getOrderIdFromPersonBuyReport(buyerInformation)
      ).unwrap();
      const { uri, encoded } = await dispatch(getOpayUriEncoded({ orderId, languageCode: i18n.language })).unwrap();

      dispatch(setOpayData({ uri, encoded }));
      dispatch(isCompany ? setCompanyBuyerInformation(buyerInformation) : setPersonBuyerInformation(buyerInformation));
      dispatch(completeStep(1));
      dispatch(setBuyReportStep(2));
    } catch (err) {
      console.error(err);
      dispatch(unCompleteStep(1));
    }
  };

  const handleContinueButton = async () => {
    try {
      await form.validateFields();
      handleOnFinish(form.getFieldsValue());
    } catch (errorInfo) {
      if (errorInfo?.errorFields) {
        const formValues = form.getFieldsValue();
        const errorObject = errorInfo.errorFields.reduce((acc, { name, errors }) => {
          const fieldName = name[0];
          acc[fieldName] = formValues[fieldName] || '(empty)';
          return acc;
        }, {});
        trackEvent('Error', {
          errorType: 'form',
          ...errorObject,
          emptyFields: errorInfo.errorFields.map(field => field.name[0]),
          buyerType: isCompany ? 'company' : 'person',
          errorFieldCount: errorInfo.errorFields.length,
        });
      }
    }
  };

  const handleBuyerChange = e => {
    dispatch(setBuyReportBuyerType(e.target.value));
    form.setFieldsValue({ name: '', address: '', payCode: '', companyCode: '', email: '' });
  };

  const RulesAndOptions = () => {
    return (
      <Form
        form={form}
        onFinish={handleOnFinish}
        name="consultationRegisterForm"
        className="form-compact form-padded"
        labelCol={{ span: 24 }}
        initialValues={isCompany ? { ...companyBuyerInformation } : { ...personBuyerInformation }}
      >
        <Row gutter={[16, 16]} className="buy-report-checks">
          <Col xs={24}>
            <Form.Item
              name="isTaxInvoiceRequired"
              valuePropName="checked"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Checkbox onChange={e => setVatBillRequired(e.target.checked)}>
                {t('companyResult.buyReport.check.vat')}
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="agreedConsultation"
              valuePropName="checked"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Checkbox>{t('companyResult.buyReport.check.consultation')}</Checkbox>
            </Form.Item>
            <Form.Item
              name="isPrivacyAndRulesAccepted"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: t('companyResult.buyReport.check.privacyAndRules.required'),
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error(t('companyResult.buyReport.check.privacyAndRules.required'))),
                },
              ]}
              required
            >
              <Checkbox className="required">
                <Trans
                  i18nKey="companyResult.buyReport.check.privacyAndRules"
                  components={{
                    link1: (
                      <a
                        href={i18n.language === 'en-US' ? privacyPolicy : privacyPolicyLt}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="underlined"
                      />
                    ),
                    link2: (
                      <a
                        href={i18n.language === 'en-US' ? terms : termsLt}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="underlined"
                      />
                    ),
                  }}
                />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={11} xl={10} xxl={10}>
          <SingleResult carded result={{ code: companyCode, name: companyName, status, address }}>
            <Divider />
            <Row gutter={[30, 0]}>
              <Col span={24}>
                <strong>{t('companyResult.reportPrice.price')}</strong>
              </Col>
              <Col xs={16} sm={12} md={12} lg={15} xl={16} xxl={11}>
                {t('companyResult.buyReport.reportTable.professionalReport')}
              </Col>
              <Col xs={8} sm={12} md={12} lg={9} xl={8} xxl={13}>
                <strong>
                  <Trans i18nKey="companyResult.reportPrice.nonFinancialStatement" />
                </strong>
              </Col>
            </Row>
            <Divider />
            <div>
              <strong>{t('companyResult.buyReport.buyer')}</strong>
            </div>
            <Radio.Group onChange={e => handleBuyerChange(e)} value={buyerType}>
              <Radio style={radioStyle} value={'company'}>
                {t('companyResult.buyReport.buyerType.company')}
              </Radio>
              <Radio style={radioStyle} value={'individual'}>
                {t('companyResult.buyReport.buyerType.person')}
              </Radio>
            </Radio.Group>
            {windowWidth > 991 && (
              <>
                <Divider />
                <RulesAndOptions />
              </>
            )}
          </SingleResult>
        </Col>
        <Col xs={24} sm={24} md={24} lg={13} xl={14} xxl={14}>
          <strong>{t(`companyResult.buyReport.form.title.${buyerType}`)}</strong>
          <div className="buy-report-form">
            <Form
              form={form}
              onFinish={handleOnFinish}
              className="form-compact form-padded"
              labelCol={{ span: 24 }}
              initialValues={isCompany ? { ...companyBuyerInformation } : { ...personBuyerInformation }}
            >
              {isCompany ? <CompanyOrderForm form={form} /> : <IndividualOrderForm vatBillRequired={vatBillRequired} />}
            </Form>
            {windowWidth <= 991 && <RulesAndOptions />}
          </div>
        </Col>
      </Row>
      <OrderActions
        backBtnId="company-result-back-second"
        submitBtnId="company-result-next-second"
        handleBackButton={() => dispatch(setPage('report'))}
        handleContinueButton={handleContinueButton}
      />
    </>
  );
};

export default OrderInformation;
