import { createSelector } from 'reselect';
import _pick from 'lodash.pick';
import { createEmployeesTable, createVehicleFleetTable, createVehiclesTable } from 'utils/report/professionalReport';
import isAfter from 'date-fns/isAfter';
import subYears from 'date-fns/subYears';

export const basicInfo = createSelector(
  state => state.startReport?.basicData,
  basicData =>
    _pick(basicData, [
      'basicInformation',
      'pressReleases',
      'authorizedCapital',
      'activitiesRestrictions',
      'activitiesRestrictionsHistory',
      'restructurizations',
      'bankruptcyInformation',
      'manager',
      'boardMember',
      'contacts',
      'creditLimitCountedAmount',
      'statistics',
      'vehicles',
      'vehiclesDetails',
      'vehiclesFleetValues',
      'licenses',
      'employees',
      'employeesNumber',
      'jadisInformation',
      'jadisInformationHistory',
      'wonPublicProcurementTenders',
      'wonPublicProcurementTendersContracts',
      'wonPublicProcurementTendersContractsChanges',
      'announcedPublicProcurementTenders',
      'announcedPublicProcurementTendersContracts',
      'announcedPublicProcurementTendersContractsChanges',
      'companyStatisticsBySectors',
      'isAvailableCreditLimitCountedAmount',
      'socialInsuranceContributionLastYearsSummary',
      'socialInsuranceContributions',
    ])
);

export const pressReleasesInfo = createSelector(
  state => state.startReport?.basicData,
  basicData => _pick(basicData, ['pressReleasesVersloZinios'])
);

export const selectOrderDetails = createSelector(
  state => state.startReport,
  startReport => startReport?.orderDetails || {}
);

export const selectBasicInformation = createSelector(
  state => state.startReport?.summary,
  summary => summary?.basicInformation || {}
);

export const basicVehicles = createSelector(
  state => state.startReport?.basicData,
  ({ vehicles, vehiclesDetails, vehiclesFleetValues = {} }) => {
    const { ownersAndManagers = [], managers = [], owners = [] } = vehiclesFleetValues;

    return {
      vehicles: Object.keys(vehicles)
        .map(category => (vehicles[category].length > 0 ? createVehiclesTable(vehicles, category) : null))
        .filter(hasValue => hasValue),
      vehiclesDetails,
      vehiclesFleetOwnersAndManagers: createVehicleFleetTable(ownersAndManagers),
      vehiclesFleetManagers: createVehicleFleetTable(managers),
      vehiclesFleetOwners: createVehicleFleetTable(owners),
    };
  }
);

export const basicEmployeesAndWages = createSelector(
  state => state.startReport?.basicData,
  ({ employeesNumber, wages, companyEmployeesSectorsNumbers }) => ({
    employeesNumber: employeesNumber.filter(({ date }) => isAfter(new Date(date), subYears(new Date(), 10))),
    employeesTable: createEmployeesTable(employeesNumber),
    wages: wages?.map(w => {
      const averageLabel = Math.round(w.average);
      return { ...w, averageLabel };
    }),
    companyEmployeesSectorsNumbers,
  })
);

export const selectLicenses = createSelector(
  state => state.startReport?.licenses,
  licenses => licenses || {}
);
