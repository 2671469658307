import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';

import { ReactComponent as PeopleIcon } from 'assets/img/icons/people.svg';

import { Col, Row, Typography, Card } from 'antd';
import Icon from '@ant-design/icons';
import { BlockHeader } from '../../BlockHeader';
import SupervisoryBoardMembers from '../tables/SupervisoryBoardMembers';

const { Text } = Typography;

const Board = ({ supervisoryBoardMembers, supervisoryBoardMembersHistory }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const historicalColumns = [
    {
      title: t('global.dateFrom'),
      dataIndex: 'dateFrom',
      key: 'dateFrom',
      render: TableColumnDate,
      sorter: (a, b) => new Date(b.dateFrom) - new Date(a.dateFrom),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('global.dateTo'),
      dataIndex: 'dateTo',
      key: 'dateTo',
      render: TableColumnDate,
      sorter: (a, b) => new Date(b.dateTo) - new Date(a.dateTo),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.supervisoryBoardMembers.name_surname_position'),
      dataIndex: 'name',
      key: 'name',
      render: (name, { role }) => `${name}, ${role}`,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <p>
          <Link
            to={`/search/company?manager=${record?.name.split(',')[0]}&managerCreditinfoId=${record?.creditinfoId}`}
            target="_blank"
          >
            <Text type="info">{t('global.managedCompaniesSearch')}</Text>
          </Link>
        </p>
      ),
    },
  ];

  return (
    <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
      <div>
        <Row id="board" justify="start">
          <Col>
            <BlockHeader
              icon={<Icon component={PeopleIcon} className="icon--gray" />}
              title={t('professionalReport.basicData.supervisoryBoardMembers.title')}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <SupervisoryBoardMembers supervisoryBoardMembers={supervisoryBoardMembers} />
          </Col>
        </Row>
        {supervisoryBoardMembersHistory?.length ? (
          <Row>
            <CollapsibleTable
              className="space-top-md"
              title={t('professionalReport.basicData.supervisoryBoardMembers.historicalInformation.title')}
              data={supervisoryBoardMembersHistory}
              columns={isUiHidden ? historicalColumns.slice(0, historicalColumns.length - 1) : historicalColumns}
              bordered
            />
          </Row>
        ) : null}
      </div>
    </Card>
  );
};

export default Board;
