import { Button, Card, Col, Row } from 'antd';
import CompanyDataCard from '../../../../components/reusable/CompanyDataCard';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18next';
import ProfReportLT from '../../../../assets/pdf/prof-report_lt-LT.pdf';
import ProfReportEN from '../../../../assets/pdf/prof-report_en-US.pdf';
import React from 'react';
import Title from 'antd/lib/typography/Title';

export const ExplanationRow = ({ handleBuyReport }) => {
  const { t } = useTranslation();

  const getReportExampleByLang = lang => {
    switch (lang) {
      case 'lt-LT':
        return ProfReportLT;
      case 'en-US':
        return ProfReportEN;
      default:
        return ProfReportLT;
    }
  };

  return (
    <Row gutter={[30]}>
      <Col md={24} lg={12} style={{ display: 'flex' }}>
        <Card className={`card-simple card-simple-padding-none space-vertical-sm has-shadow`} style={{ width: '100%' }}>
          <Row style={{ height: '100%' }}>
            <Col span={24}>
              <Title className="fw-600" level={4}>
                {t('companyResult.report.explanationBuy.title')}
              </Title>
              <ul style={{ paddingLeft: 20 }}>
                <li>
                  <Trans i18nKey="companyResult.report.explanationBuy.paragraph.1" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.explanationBuy.paragraph.2" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.explanationBuy.paragraph.3" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.explanationBuy.paragraph.4" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.explanationBuy.paragraph.5" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.explanationBuy.paragraph.6" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.explanationBuy.paragraph.7" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.explanationBuy.paragraph.8" components={{ bold: <strong /> }} />{' '}
                </li>
              </ul>
            </Col>
            <Col span={24} style={{ alignSelf: 'end' }}>
              <Button className="btn-success" onClick={handleBuyReport}>
                {t('companyResult.button.buyReport')}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col md={24} lg={12} style={{ display: 'flex' }}>
        <Card className={`card-simple card-simple-padding-none space-vertical-sm has-shadow`} style={{ width: '100%' }}>
          <Row>
            <Col span={24}>
              <Title className="fw-600" level={4}>
                {t('companyResult.report.sample.title')}
              </Title>
              <ul style={{ paddingLeft: 20 }}>
                <li>
                  <Trans i18nKey="companyResult.report.sample.paragraph.1" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.sample.paragraph.2" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.sample.paragraph.3" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.sample.paragraph.4" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.sample.paragraph.5" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.sample.paragraph.6" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.sample.paragraph.7" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.sample.paragraph.8" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.sample.paragraph.9" components={{ bold: <strong /> }} />{' '}
                </li>
                <li>
                  <Trans i18nKey="companyResult.report.sample.paragraph.10" components={{ bold: <strong /> }} />{' '}
                </li>
              </ul>
              <Row gutter={[16, 0]}>
                <Col>
                  <span>{t('companyResult.buyReport.reportTable.ps')}</span>
                </Col>
                <Col>
                  <span>{t('companyResult.buyReport.reportTable.pss')}</span>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ alignSelf: 'end' }} className="space-top-xsm">
              <a href="#" onClick={() => window.open(`${getReportExampleByLang(i18n.language)}`, '_blank')}>
                <Button className="btn-success-bordered">{t('companyResult.buyReport.reportTable.example')}</Button>
              </a>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
