import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableColumnDate from 'components/reusable/TableColumnDate';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

import { Table, Typography } from 'antd';

const { Text } = Typography;

const SupervisoryBoardMembers = ({ supervisoryBoardMembers }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const supervisoryBoardMembersColumns = [
    {
      title: t('professionalReport.basicData.supervisoryBoardMembers.date'),
      dataIndex: 'dateFrom',
      render: TableColumnDate,
      key: 'dateForm',
      sorter: (a, b) => new Date(b.dateFrom) - new Date(a.dateFrom),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.supervisoryBoardMembers.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.basicData.supervisoryBoardMembers.role'),
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <p>
          <Link
            to={`/search/company?manager=${record?.name.split(',')[0]}&managerCreditinfoId=${record?.creditinfoId}`}
            target="_blank"
          >
            <Text type="info">{t('global.managedCompaniesSearch')}</Text>
          </Link>
        </p>
      ),
    },
  ];

  return (
    <Table
      bordered
      dataSource={supervisoryBoardMembers}
      columns={
        isUiHidden
          ? supervisoryBoardMembersColumns.slice(0, supervisoryBoardMembersColumns.length - 1)
          : supervisoryBoardMembersColumns
      }
      scroll={{ x: 'max-content' }}
      pagination={{ defaultPageSize: 10, showSizeChanger: true }}
      rowKey={({ name, dateFrom, role }) => `boardMember-${name}-${dateFrom}-${role}`}
      showSorterTooltip={false}
    />
  );
};

export default SupervisoryBoardMembers;
