import CompanyDataCard, { CompanyDataRow } from '../../CompanyDataCard';
import { PieChartOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useNumberFormatter from '../../../../hooks/format/useNumberFormatter';
import CollapsibleTable from '../../CollapsibleTable';
import TableColumnDate from '../../TableColumnDate';
import useIsUiHidden from '../../../../hooks/dom/useIsUiHidden';

export const ProcurementInformation = ({
  wonPublicProcurementTenders,
  wonPublicProcurementTendersContracts,
  wonPublicProcurementTendersContractsChanges,
  announcedPublicProcurementTenders,
  announcedPublicProcurementTendersContracts,
  announcedPublicProcurementTendersContractsChanges,
}) => {
  const { t } = useTranslation();
  const formatCurrency = useNumberFormatter();
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const isUiHidden = useIsUiHidden();
  const detailedInfoColumns = buyer => [
    {
      title: t('startReport.basic.public.procurementInformation.date'),
      dataIndex: 'date',
      key: 'date',
      render: TableColumnDate,
      sorter: (a, b) => new Date(b.date) - new Date(a.date),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t(`startReport.basic.public.procurementInformation.${buyer ? 'buyer' : 'provider'}`),
      dataIndex: 'party',
      key: 'party',
      sorter: (a, b) => a.party.localeCompare(b.party),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 60 : null,
    },
    {
      title: t('startReport.basic.public.procurementInformation.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: amount => {
        return formatNumber(amount);
      },
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 60 : null,
    },
    {
      title: t('startReport.basic.public.procurementInformation.contractObject'),
      dataIndex: 'contractObject',
      key: 'contractObject',
      sorter: (a, b) => a.contractObject.localeCompare(b.contractObject),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 60 : null,
    },
  ];

  return (
    <CompanyDataCard
      icon={<PieChartOutlined className="icon--gray" />}
      title={t('startReport.basic.public.procurementInformation.title')}
    >
      <Col>
        <CompanyDataRow
          value={t('startReport.basic.procurementInformation.wonPublicProcurementTenders.title')}
          extraClass="fw-600"
        />
        <CompanyDataRow
          type={t('startReport.basic.procurementInformation.wonPublicProcurementTenders.contractsWorth')}
          value={
            wonPublicProcurementTenders?.contractsWorth
              ? t('startReport.basic.procurementInformation.wonPublicProcurementTenders.contractsWorth.value', {
                  contractsWorth: formatCurrency(wonPublicProcurementTenders?.contractsWorth),
                })
              : t('startReport.basic.procurementInformation.noInformation')
          }
        />
        <CompanyDataRow
          type={t('startReport.basic.procurementInformation.wonPublicProcurementTenders.numberOfTenders')}
          value={
            wonPublicProcurementTenders?.numberOfTenders
              ? t('startReport.basic.procurementInformation.wonPublicProcurementTenders.numberOfTenders.value', {
                  numberOfTenders: wonPublicProcurementTenders?.numberOfTenders,
                })
              : t('startReport.basic.procurementInformation.noInformation')
          }
        />
        {wonPublicProcurementTendersContracts?.length ? (
          <CollapsibleTable
            className="space-top-md"
            title={t('startReport.basic.public.procurementInformation.Contracts')}
            data={wonPublicProcurementTendersContracts}
            columns={detailedInfoColumns(true)}
            pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
            bordered
            showSorterTooltip={false}
          />
        ) : null}
        {wonPublicProcurementTendersContractsChanges?.length ? (
          <CollapsibleTable
            className="space-top-md"
            title={t('startReport.basic.public.procurementInformation.ContractsChanges')}
            data={wonPublicProcurementTendersContractsChanges}
            columns={detailedInfoColumns(true)}
            pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
            bordered
            showSorterTooltip={false}
          />
        ) : null}
        <p></p>

        <CompanyDataRow
          value={t('startReport.basic.procurementInformation.announcedPublicProcurementTenders.title')}
          extraClass="fw-600"
        />
        <CompanyDataRow
          type={t('startReport.basic.procurementInformation.announcedPublicProcurementTenders.contractsWorth')}
          value={
            announcedPublicProcurementTenders?.contractsWorth
              ? t('startReport.basic.procurementInformation.announcedPublicProcurementTenders.contractsWorth.value', {
                  contractsWorth: formatCurrency(announcedPublicProcurementTenders?.contractsWorth),
                })
              : t('startReport.basic.procurementInformation.noInformation')
          }
        />
        <CompanyDataRow
          type={t('startReport.basic.procurementInformation.announcedPublicProcurementTenders.numberOfTenders')}
          value={
            announcedPublicProcurementTenders?.numberOfTenders
              ? t('startReport.basic.procurementInformation.announcedPublicProcurementTenders.numberOfTenders.value', {
                  numberOfTenders: announcedPublicProcurementTenders?.numberOfTenders,
                })
              : t('startReport.basic.procurementInformation.noInformation')
          }
        />
        {announcedPublicProcurementTendersContracts?.length ? (
          <CollapsibleTable
            className="space-top-md"
            title={t('startReport.basic.public.procurementInformation.Contracts')}
            data={announcedPublicProcurementTendersContracts}
            columns={detailedInfoColumns(false)}
            pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
            bordered
            showSorterTooltip={false}
          />
        ) : null}
        {announcedPublicProcurementTendersContractsChanges?.length ? (
          <CollapsibleTable
            className="space-top-md"
            title={t('startReport.basic.public.procurementInformation.ContractsChanges')}
            data={announcedPublicProcurementTendersContractsChanges}
            columns={detailedInfoColumns(false)}
            pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
            bordered
            showSorterTooltip={false}
          />
        ) : null}
        <p></p>
        <CompanyDataRow disclaimer={t('startReport.basic.procurementInformation.disclaimer')} />
        <CompanyDataRow disclaimer={t('startReport.basic.procurementInformation.dataSource')} />
      </Col>
    </CompanyDataCard>
  );
};
