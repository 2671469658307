import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';

import { setActiveTab } from 'store/entities/professionalReport';

import CompanyBasicInformation from 'components/reusable/report/cards/CompanyBasicInformation';
import CompanyManager from 'components/reusable/report/cards/CompanyManager';
import Contact from './Contact';
import { ActivitiesRestrictions } from '../../../components/reusable/report/cards/ActivitiesRestrictions';

const Basic = () => {
  const dispatch = useDispatch();

  const { basicInfo } = useSelector(state => state.mainInformation);

  return (
    <div id="basic">
      <VisibilitySensor
        offset={{ bottom: 50 }}
        scrollCheck
        intervalDelay={10}
        onChange={isVisible => isVisible && dispatch(setActiveTab('basic'))}
      >
        <CompanyBasicInformation companyData={basicInfo?.basicInformation} />
      </VisibilitySensor>
      {/*<ActivitiesRestrictions*/}
      {/*  activitiesRestrictions={basicInfo?.activitiesRestrictions}*/}
      {/*  activitiesRestrictionsHistory={basicInfo?.activitiesRestrictionsHistory}*/}
      {/*/>*/}
      <CompanyManager
        managerData={basicInfo?.managersInfo?.currentManager}
        managersHistory={basicInfo?.managersInfo?.managersHistory}
      />
      <Contact contactData={basicInfo?.companyContact} />
    </div>
  );
};

export default Basic;
