import axios from 'axios';
import { showErrorNotification } from 'utils/notification';
import { signOut } from 'store/entities/user/thunks';
import { trackEvent } from '../utils/general/amplitudeService';
import jwt_decode from 'jwt-decode';
import { differenceInSeconds } from 'date-fns';

export const API_PATH = process.env.REACT_APP_API_PATH ?? '/api/v1';

export const fetchFromApi = (path, options = defaultRequestOptions()) =>
  axios({
    url: `${API_PATH}${path}`,
    ...options,
  })
    .then(response => response.data)
    .catch(error => {
      return handleApiError({ path, error, options });
    });

export const handleApiError = ({ path, error, options }) => {
  (error.response.data.messages || error.response.data.message) &&
    error.response.data.messages[0] !== 'Captcha validation failed' &&
    showErrorNotification(error.response.data.messages ? error.response.data.messages[0] : error.response.data.message);
  const sanitizedData = options?.data ? { ...options.data, password: undefined } : undefined;

  trackEvent('Error', {
    errorType: `System ${error.response.status}`,
    errorMessage: error.response.data.messages ? error.response.data.messages[0] : error.response.data.message,
    pagePath: `${API_PATH}${path ? path : undefined}`,
    ...sanitizedData,
  });
  if (error.response.status === 401) handleUnauthorized(error.response);
  return Promise.reject(error);
};

const handleUnauthorized = resp => {
  const exceptions = ['change-password'];
  if (!exceptions.some(e => resp.config.url.includes(e))) {
    import('../store').then(store => {
      const dispatch = store.default.dispatch;
      if (dispatch && resp.config.headers.Authorization) {
        const token = localStorage.getItem('token');
        if (token) {
          const decoded = jwt_decode(token);
          const expSeconds = decoded?.exp;
          const expMilliseconds = expSeconds * 1000;
          const expirationDate = new Date(expMilliseconds);
          const currentDate = new Date();
          const remainingSeconds = differenceInSeconds(expirationDate, currentDate);
          if (0 > remainingSeconds) {
            dispatch(signOut());

            if (remainingSeconds > -3600) {
              const sessionSyncChannel = new BroadcastChannel('session_sync_channel');
              sessionSyncChannel.postMessage({ type: 'SESSION_END' });
            }
          }
          localStorage.removeItem('token');
        }
      }
    });
  }
};

const defaultRequestOptions = () => {
  const token = window.localStorage.getItem('token');
  return {
    headers: {
      ...(!!token && {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      }),
      'Content-Type': 'application/json',
    },
  };
};

const fileRequestOptions = () => {
  const token = window.localStorage.getItem('token');
  return {
    headers: {
      ...(!!token && {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      }),
      'Content-Type': 'multipart/form-data',
    },
  };
};

export const optionsForFilePostRequest = data => ({
  ...fileRequestOptions(),
  method: 'post',
  data,
});

export const optionsForPostRequest = data => ({
  ...defaultRequestOptions(),
  method: 'post',
  data,
});

export const optionsForGetRequest = data => ({
  ...defaultRequestOptions(),
  method: 'get',
});

export const optionsForDeleteRequest = data => ({
  ...defaultRequestOptions(),
  method: 'delete',
  data,
});

export const optionsForPutRequest = data => ({
  ...defaultRequestOptions(),
  method: 'put',
  data,
});

export const optionsForPatchRequest = data => ({
  ...defaultRequestOptions(),
  method: 'patch',
  ...(data && { data }),
});

export const optionsForFileRequest = data => ({
  headers: new Headers({
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
  }),
  method: 'post',
  data,
});

axios.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
