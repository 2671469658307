import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import useRedirect from 'hooks/navigation/useRedirect';
import useProfReportState from 'hooks/report/professionalReport/useProfReportState';
import { expertEvaluation, getOrderId } from 'store/entities/professionalReport/thunks';
import { resetProfessionalReport, setExpertReportModalOpen } from 'store/entities/professionalReport';
import FullReport from './FullReport';
import LoadingSpinner from 'components/reusable/LoadingSpinner';

import { Col, BackTop, Button } from 'antd';
import ReportHeader from '../../components/reusable/report/ReportHeader';
import CustomReportModal from './CustomReportModal';
import useCurrentLanguage from '../../hooks/user/useCurrentLanguage';

const ProfessionalReport = () => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { orderId, orderDetails, summary } = useSelector(state => state.professionalReport);
  const isUiHidden = useIsUiHidden();
  const redirect = useRedirect();
  const { isLoading, hasError } = useProfReportState();
  const lang = useCurrentLanguage();

  const orderReport = async () => {
    const orderIdResult = await dispatch(getOrderId({ code: companyId, isCompany: true }));

    if (!getOrderId.fulfilled.match(orderIdResult)) {
      redirect('/search/company');
    }
  };

  useEffect(() => {
    orderReport();

    return () => dispatch(resetProfessionalReport());
  }, []);

  return (
    <LoadingSpinner size="large" spinning={isLoading}>
      {hasError && <Redirect to="/search/company" exact />}
      <CustomReportModal />
      <div className="container">
        <Col span={24}>
          <ReportHeader title={orderDetails?.companyTitle} subTitle={t('professionalReport.title')}>
            {summary?.expertEvaluation?.isAllowed && (
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 9, order: 1 }}
                lg={{ span: 12, order: 1 }}
                className="justify-content-end align-items-end"
              >
                <Button
                  style={{ width: lang === 'en-US' ? 200 : 245 }}
                  onClick={() => {
                    dispatch(setExpertReportModalOpen(true));
                    dispatch(expertEvaluation({ orderId }));
                  }}
                >
                  {t('professionalReport.companyRatings.orderExpertReport.title')}
                </Button>
              </Col>
            )}
          </ReportHeader>
          {orderId && <FullReport />}
        </Col>
        {!isUiHidden && <BackTop />}
      </div>
    </LoadingSpinner>
  );
};

export default ProfessionalReport;
