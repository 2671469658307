import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Affix, Col, Menu, Row, Typography } from 'antd';
import cx from 'classnames';

import Summary from 'views/StartReport/Summary';
import BasicData from 'views/StartReport/BasicData';
import Ratings from 'views/StartReport/Ratings';
import NegativeData from 'views/StartReport/NegativeData';
import FinancialData from 'views/StartReport/FinancialData';
import VisibilitySensorBlock from 'components/reusable/report/VisibilitySensorBlock';
import { scrollToSection } from 'utils/dom';
import { getStartReportPdf } from '../../../store/entities/startReport/thunks';
import { DownloadPdfBtn } from '../../../components/reusable/report/buttons';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const { Title } = Typography;

const FullReport = ({ hideNavigationTabs = false }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('summary');
  const [selectedTab, setSelectedTab] = useState('');
  const [menuAffixed, setMenuAffixed] = useState(false);
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { orderId } = useSelector(state => state.startReport);

  const menuClassName = cx('tabs', {
    'tabs--affixed': menuAffixed,
  });

  const pdfBtnStyle = {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  };

  const menuTabs = ['summary', 'basicData', 'ratings', 'negativeData', 'financialData'];

  const handleMenuClick = e => {
    e.domEvent.preventDefault();
    e.domEvent.stopPropagation();
    scrollToSection(e.key);
    setSelectedTab(e.key);
    setActiveTab(e.key);
    setTimeout(() => {
      setSelectedTab('');
    }, 100);
  };

  const handleTabChange = (tabName, scrollDirection, isVisible) => {
    if (selectedTab) return false;
    if (scrollDirection === 'down' && !isVisible) {
      setActiveTab(tabName);
    }
    if (scrollDirection === 'up' && !isVisible) {
      if (tabName === 'summary') {
        setActiveTab(tabName);
      } else {
        setActiveTab(menuTabs[menuTabs.indexOf(tabName) - 1]);
      }
    }
  };

  return (
    <Row className="start-full-report">
      {!hideNavigationTabs && (
        <Col span={24}>
          <Affix offsetTop={0} onChange={affixed => setMenuAffixed(affixed)} className="affix-style">
            <Col xs={{ span: 24 }} lg={{ span: 0 }}>
              <DownloadPdfBtn
                fetcher={() =>
                  dispatch(
                    getStartReportPdf({
                      companyCode: companyId,
                      orderId,
                    })
                  )
                }
                reportType="start"
                style={{ position: 'relative', float: 'right' }}
              />
            </Col>
            <Col xs={15} sm={17} md={19} lg={24}>
              <Menu
                onClick={handleMenuClick}
                selectedKeys={activeTab}
                mode="horizontal"
                className={menuClassName}
                selectable
              >
                {menuTabs.map(tab => (
                  <Menu.Item key={tab}>{t(`startReport.tab.${tab}`)}</Menu.Item>
                ))}
              </Menu>
            </Col>
            <Col xs={{ span: 0 }} lg={{ span: 12 }} style={pdfBtnStyle}>
              <DownloadPdfBtn
                fetcher={() =>
                  dispatch(
                    getStartReportPdf({
                      companyCode: companyId,
                      orderId,
                    })
                  )
                }
                reportType="start"
                style={{ position: 'relative', float: 'right' }}
              />
            </Col>
          </Affix>
        </Col>
      )}
      <Col span={24}>
        <VisibilitySensorBlock id="summary" key="summary" onChange={handleTabChange}>
          <>
            <Title level={5} className="fs-18 fw-600 space-top-md">
              {t('startReport.tab.summary')}
            </Title>
            <Summary />
          </>
        </VisibilitySensorBlock>
        <VisibilitySensorBlock id="basicData" key="basicData" onChange={handleTabChange}>
          <>
            <Title level={5} className="fs-18 fw-600 space-top-md">
              {t('startReport.tab.basicData')}
            </Title>
            <BasicData />
          </>
        </VisibilitySensorBlock>
        <VisibilitySensorBlock id="ratings" key="ratings" onChange={handleTabChange}>
          <>
            <Title level={5} className="fs-18 fw-600 space-top-md">
              {t('startReport.tab.ratings')}
            </Title>
            <Ratings />
          </>
        </VisibilitySensorBlock>
        <VisibilitySensorBlock id="negativeData" key="negativeData" onChange={handleTabChange}>
          <>
            <Title level={5} className="fs-18 fw-600 space-top-md">
              {t('startReport.tab.negativeData')}
            </Title>
            <NegativeData />
          </>
        </VisibilitySensorBlock>
        <VisibilitySensorBlock id="financialData" key="financialData" onChange={handleTabChange}>
          <>
            <Title level={5} className="fs-18 fw-600 space-top-md">
              {t('startReport.tab.financialData')}
            </Title>
            <FinancialData />
          </>
        </VisibilitySensorBlock>
      </Col>
    </Row>
  );
};

export default FullReport;
