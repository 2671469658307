import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useFormatDate, { DATE_FORMAT_LONG, DATE_FORMAT_YEAR_ONLY } from 'hooks/format/useFormatDate';
import useNumberFormatter from 'hooks/format/useNumberFormatter';

import { SummarySmallBlock } from 'components/reusable/CompanyDataCard';
import OrderExpertReport from '../CompanyRatings/OrderExpertReport';

import { Col, Row } from 'antd';
import useIsUiHidden from '../../../hooks/dom/useIsUiHidden';
import { isOlderThanXYear } from '../../../helpers/common';

const BasicInformation = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const formatCurrency = useNumberFormatter();
  const { summary } = useSelector(state => state.professionalReport);
  const isUiHidden = useIsUiHidden();

  const handleNegativeClass = (value, date, yearInterval = 2) => {
    return value && !isOlderThanXYear(date, yearInterval);
  };

  return (
    <>
      <div className="space-vertical-sm small-blocks">
        <Row gutter={isUiHidden ? [3] : [16, 16]} className="ant-row-pdf-gap">
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            title={t('professionalReport.summary.basicInformation.creditLimit')}
            value={
              summary?.basicInformation?.automatedCreditLimitAmount
                ? formatCurrency(summary?.basicInformation?.automatedCreditLimitAmount)
                : '-'
            }
            explanation={
              !summary?.basicInformation?.automatedCreditLimitAmount &&
              t('professionalReport.summary.basicInformation.creditLimit.explanation')
            }
            icon="creditLimit"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            negativeClass={handleNegativeClass(summary?.activeDebts?.total)}
            value={summary?.activeDebts?.total || t('global.noEntries')}
            title={t('professionalReport.summary.openDebts.title')}
            explanation={
              summary?.activeDebts?.total
                ? t('professionalReport.summary.openDebts.explanation', {
                    date: summary?.activeDebts?.updateDate ? formatDate(summary.activeDebts.updateDate) : '-',
                    lastDate: summary?.activeDebts?.latestDate ? formatDate(summary.activeDebts.latestDate) : '-',
                    amount: summary?.activeDebts?.amount ? formatCurrency(summary.activeDebts.amount) : '-',
                    days: summary?.activeDebts?.averageDays ? summary.activeDebts.averageDays : '-',
                    count: summary?.activeDebts?.total ? summary.activeDebts.total : '-',
                  })
                : false
            }
            icon="activeDebts"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            explanation={
              summary.paidDebts.total
                ? t('professionalReport.summary.paymentHistory.explanation', {
                    amount: summary?.paidDebts?.total || '-',
                    date: formatDate(summary?.paidDebts?.latestDate),
                    days: summary?.paidDebts?.averageDays,
                  })
                : false
            }
            title={t('professionalReport.summary.paymentHistory.title')}
            value={summary?.paidDebts?.total || t('global.noEntries')}
            negativeClass={handleNegativeClass(summary?.paidDebts?.total, summary?.paidDebts?.latestDate)}
            icon="paymentHistory"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            explanation={
              summary.paidDebts.total
                ? t('professionalReport.summary.basicInformation.propertyArrests.explanation', {
                    unregisteredArrests: summary?.unregisteredArrests?.total || '0',
                    registeredArrests: summary?.registeredArrests?.total || '0',
                    latestDate: formatDate(summary?.registeredArrests?.latestDate),
                  })
                : false
            }
            title={t('professionalReport.summary.basicInformation.propertyArrests')}
            value={summary?.registeredArrests?.total + summary?.unregisteredArrests?.total || t('global.noEntries')}
            negativeClass={handleNegativeClass(
              summary?.registeredArrests?.total,
              summary?.registeredArrests?.latestDate,
              1
            )}
            icon="registeredArrests"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            explanation={
              summary?.legalProceedings?.total
                ? t('professionalReport.summary.numberOfLegalProceedings.explanation', {
                    totalPlaintiff: summary.legalProceedings?.totalPlaintiff || '0',
                    totalThirdParty: summary.legalProceedings?.totalThirdParty || '0',
                    totalDefendant: summary.legalProceedings?.totalDefendant || '0',
                    latestDate: formatDate(new Date(summary.legalProceedings?.latestDate), 'P'),
                  })
                : false
            }
            title={t('professionalReport.summary.numberOfLegalProceedings.title')}
            value={summary?.legalProceedings?.total || t('global.noEntries')}
            negativeClass={handleNegativeClass(
              summary?.legalProceedings?.totalDefendant,
              summary?.legalProceedings?.latestDateDefendant
            )}
            icon="legalProceedings"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            title={t('professionalReport.summary.negativeInformation.title')}
            value={summary?.negativeInformation?.total || t('global.noEntries')}
            negativeClass={handleNegativeClass(
              summary?.negativeInformation?.total,
              summary?.negativeInformation?.latestDate
            )}
            icon="negativeInformation"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            explanation={
              summary?.employees?.employeeNumber
                ? t('professionalReport.summary.employees.numberOfEmployees.basic.explanation', {
                    date: formatDate(summary?.employees?.employeeNumberDate),
                  })
                : false
            }
            title={t('professionalReport.summary.employees.numberOfEmployees.basic')}
            value={summary?.employees?.employeeNumber || t('global.noEntries')}
            icon="employeeNumber"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            title={
              <Trans
                i18nKey="professionalReport.summary.finance.explanation"
                values={{
                  date: summary?.finance?.turnoverModel?.year
                    ? formatDate(summary?.finance?.turnoverModel?.year, DATE_FORMAT_YEAR_ONLY)
                    : '-',
                }}
              />
            }
            value={
              summary?.finance?.turnoverModel?.amount
                ? formatCurrency(summary.finance.turnoverModel.amount)
                : t('global.noEntries')
            }
            icon="income"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            explanation={
              summary?.sodraDebts?.hasDebts
                ? t('professionalReport.summary.sodraDebts.explanation', {
                    deferredAmount: formatCurrency(summary?.sodraDebts?.deffAmount),
                    date: summary?.sodraDebts?.latestDate
                      ? formatDate(summary?.sodraDebts?.latestDate, DATE_FORMAT_LONG)
                      : '0',
                  })
                : false
            }
            title={t('professionalReport.summary.sodraDebts.title')}
            value={summary?.sodraDebts?.amount ? formatCurrency(summary.sodraDebts.amount) : t('global.noEntries')}
            negativeClass={summary?.sodraDebts?.amount && summary?.sodraDebts?.deffAmount < summary?.sodraDebts?.amount}
            icon="sodraDebts"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            title={t('professionalReport.summary.basicInformation.numberOfShareholders')}
            value={summary?.basicInformation?.numberOfShareholders || t('global.noEntries')}
            icon="numberOfShareholders"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            explanation={t('companyResult.summary.reportNumbers.value', {
              reports1: summary?.statistics?.lastMonth,
              reports2: summary?.statistics?.lastQuarter,
              reports3: summary?.statistics?.lastHalfYear,
            })}
            title={t('professionalReport.summary.searches.title')}
            value={summary?.statistics?.lastHalfYear || t('global.noEntries')}
            icon="reportNumbers"
          />
          <SummarySmallBlock
            pdfAlign={isUiHidden}
            explanation={t('professionalReport.summary.employees.explanation', {
              averageSalaryDate: summary?.employees?.averageSalaryDate
                ? formatDate(summary?.employees?.averageSalaryDate, DATE_FORMAT_LONG)
                : '-',
              averageSalaryWomen: summary?.employees?.averageSalaryWomen
                ? formatCurrency(summary?.employees?.averageSalaryWomen)
                : t('global.noCurrency'),
              averageSalaryMen: summary?.employees?.averageSalaryMen
                ? formatCurrency(summary?.employees?.averageSalaryMen)
                : t('global.noCurrency'),
            })}
            title={t('professionalReport.summary.employees.title')}
            value={
              summary?.employees?.averageSalary
                ? formatCurrency(summary.employees.averageSalary)
                : t('global.noEntries')
            }
            icon="averageSalary"
          />
        </Row>
      </div>
      <OrderExpertReport />
    </>
  );
};

export default BasicInformation;
