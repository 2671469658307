import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import {
  getBasicData,
  getCompanyRatings,
  getFinancialData,
  getNegativeData,
  getOrderId,
  getSummary,
  orderExpertEvaluation,
  expertEvaluation,
  updateContacts,
  getProfessionalReportPdf,
  getOrderDetails,
} from './thunks';

const initialState = {
  orderId: null,
  orderIdPending: false,
  orderIdError: false,
  orderDetails: null,
  orderDetailsPending: false,
  orderDetailsError: false,
  activeTab: 'basic',
  activeFullReportTab: 'summary',
  menuAffixed: false,
  summary: null,
  summaryPending: false,
  summaryError: false,
  basicData: null,
  basicDataPending: false,
  basicDataError: false,
  negativeData: null,
  negativeDataPending: false,
  negativeDataError: false,
  companyRatings: null,
  companyRatingsPending: false,
  companyRatingsError: false,
  financialData: null,
  financialDataPending: false,
  financialDataError: false,
  expertReportModalOpen: false,
  updateContactsPending: false,
  orderExpertEvaluationPending: false,
  activePdfModal: false,
  customPdfValues: null,
  expertEvaluation: null,
};

const slice = createSlice({
  name: 'professionalReport',
  initialState: initialState,
  reducers: {
    resetProfessionalReport: state => initialState,
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setActiveFullReportTab: (state, { payload }) => {
      state.activeFullReportTab = payload;
    },
    setMenuAffixed: (state, { payload }) => {
      state.menuAffixed = payload;
    },
    setExpertReportModalOpen: (state, { payload }) => {
      state.expertReportModalOpen = payload;
    },
    setActivePdfModal: (state, { payload }) => {
      state.activePdfModal = payload;
    },
    setCustomPdfValues: (state, { payload }) => {
      state.customPdfValues = payload;
    },
  },
  extraReducers: {
    [getOrderId.fulfilled]: (state, { payload }) => {
      state.orderId = payload?.id;
      state.orderIdPending = false;
      state.orderIdError = false;
    },
    [getOrderId.pending]: state => {
      state.orderIdPending = true;
      state.orderIdError = false;
    },
    [getOrderId.rejected]: state => {
      state.orderIdPending = false;
      state.orderIdError = true;
    },
    [getOrderDetails.fulfilled]: (state, { payload }) => {
      state.orderDetails = payload;
      state.orderDetailsPending = false;
      state.orderDetailsError = false;
    },
    [getOrderDetails.pending]: state => {
      state.orderDetailsPending = true;
      state.orderDetailsError = false;
    },
    [getOrderDetails.rejected]: state => {
      state.orderDetailsPending = false;
      state.orderDetailsError = true;
    },
    [getSummary.fulfilled]: (state, { payload }) => {
      state.orderDetails = payload?.orderDetails;
      state.summary = { ...payload?.summary, lang: i18n.language };
      state.summaryPending = false;
      state.summaryError = false;
    },
    [getSummary.pending]: state => {
      state.summaryPending = true;
      state.summaryError = false;
    },
    [getSummary.rejected]: state => {
      state.summaryPending = false;
      state.summaryError = true;
    },
    [getBasicData.fulfilled]: (state, { payload }) => {
      state.basicData = { ...payload, lang: i18n.language };
      state.basicDataPending = false;
      state.basicDataError = false;
    },
    [getBasicData.pending]: state => {
      state.basicDataPending = true;
      state.basicDataError = false;
    },
    [getBasicData.rejected]: state => {
      state.basicDataPending = false;
      state.basicDataError = true;
    },
    [getCompanyRatings.fulfilled]: (state, { payload }) => {
      state.companyRatings = { ...payload, lang: i18n.language };
      state.companyRatingsPending = false;
      state.companyRatingsError = false;
    },
    [getCompanyRatings.pending]: state => {
      state.companyRatingsPending = true;
      state.companyRatingsError = false;
    },
    [getCompanyRatings.rejected]: state => {
      state.companyRatingsPending = false;
      state.companyRatingsError = true;
    },
    [getNegativeData.fulfilled]: (state, { payload }) => {
      state.negativeData = { ...payload, lang: i18n.language };
      state.negativeDataPending = false;
      state.negativeDataError = false;
    },
    [getNegativeData.pending]: state => {
      state.negativeDataPending = true;
      state.negativeDataError = false;
    },
    [getNegativeData.rejected]: state => {
      state.negativeDataPending = false;
      state.negativeDataError = true;
    },
    [getFinancialData.fulfilled]: (state, { payload }) => {
      state.financialData = { ...payload, lang: i18n.language };
      state.financialDataPending = false;
      state.financialDataError = false;
    },
    [getFinancialData.pending]: state => {
      state.financialDataPending = true;
      state.financialDataError = false;
    },
    [getFinancialData.rejected]: state => {
      state.financialDataPending = false;
      state.financialDataError = true;
    },
    [updateContacts.fulfilled]: state => {
      state.updateContactsPending = false;
    },
    [updateContacts.pending]: state => {
      state.updateContactsPending = true;
    },
    [updateContacts.rejected]: state => {
      state.updateContactsPending = false;
    },
    [orderExpertEvaluation.fulfilled]: state => {
      state.orderExpertEvaluationPending = false;
    },
    [orderExpertEvaluation.pending]: state => {
      state.orderExpertEvaluationPending = true;
    },
    [orderExpertEvaluation.rejected]: state => {
      state.orderExpertEvaluationPending = false;
    },
    [expertEvaluation.fulfilled]: (state, { payload }) => {
      state.expertEvaluation = { ...payload };
      state.orderExpertEvaluationPending = false;
    },
    [expertEvaluation.pending]: state => {
      state.orderExpertEvaluationPending = true;
    },
    [expertEvaluation.rejected]: state => {
      state.expertEvaluation = null;
      state.orderExpertEvaluationPending = false;
    },
    [getProfessionalReportPdf.pending]: state => {
      state.professionalReportPdfPending = true;
    },
    [getProfessionalReportPdf.rejected]: state => {
      state.professionalReportPdfPending = false;
    },
    [getProfessionalReportPdf.fulfilled]: state => {
      state.professionalReportPdfPending = false;
    },
  },
});

export const {
  resetProfessionalReport,
  setActiveTab,
  setActiveFullReportTab,
  setMenuAffixed,
  setExpertReportModalOpen,
  setActivePdfModal,
  setCustomPdfValues,
} = slice.actions;

export default slice.reducer;
