import React from 'react';

import { Col, Row, Typography } from 'antd';

const { Title } = Typography;

const ReportHeader = ({ title, actions, children }) => {
  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 15, order: 1 }}
        lg={{ span: 12, order: 1 }}
      >
        <Title
          level={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>{title}</span>
          <span style={{ marginLeft: '1rem', display: 'flex' }}>{actions}</span>
        </Title>
      </Col>
      {children}
    </Row>
  );
};

export default ReportHeader;
