import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { SummarySmallBlock } from 'components/reusable/CompanyDataCard';

import { Col, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { summaryInformation } from 'store/entities/companySearchResult/selectors/unregisteredReportSelector';
import useFormatDate, { DATE_FORMAT_LONG } from 'hooks/format/useFormatDate';
import useNumberFormatter from 'hooks/format/useNumberFormatter';
import { BlockHeader } from 'components/reusable/BlockHeader';
import { isOlderThanXYear } from '../../../../helpers/common';

const Summary = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const formatCurrency = useNumberFormatter();

  const { summaryInformation: summaryInformationState } = useSelector(summaryInformation);

  function parseNace2String(nace2String) {
    if (!nace2String) {
      return null;
    }

    const parts = nace2String.split(' - ');

    const code = parts[0].trim();
    const explanation = parts.slice(1).join(' - ').trim();

    return {
      code,
      explanation,
    };
  }

  const nace2Object = parseNace2String(summaryInformationState?.nace2);

  const handleNegativeClass = (value, date, yearInterval = 2) => {
    return value && !isOlderThanXYear(date, yearInterval);
  };

  return (
    <>
      <div className="space-vertical-sm small-blocks">
        <Row gutter={[30, 30]}>
          <SummarySmallBlock
            title={t('companyResult.summary.legalStatus')}
            value={
              summaryInformationState?.status ? (
                <div className={summaryInformationState?.statusCode === 1 ? 'color-success' : 'color-secondary'}>
                  {summaryInformationState?.status}
                </div>
              ) : (
                '-'
              )
            }
            icon="legalStatus"
          />
          <SummarySmallBlock
            title={t('companyResult.summary.registrationDate')}
            value={
              summaryInformationState?.registrationDate
                ? formatDate(summaryInformationState?.registrationDate, DATE_FORMAT_LONG)
                : '-'
            }
            icon="registrationDate"
          />
          <SummarySmallBlock
            title={t('companyResult.summary.companyCode')}
            value={summaryInformationState?.companyCode}
            icon="companyCode"
          />
          <SummarySmallBlock
            title={t('companyResult.summary.vatCode')}
            value={summaryInformationState?.vat ?? '-'}
            icon="vatCode"
          />
          <SummarySmallBlock
            explanation={nace2Object?.explanation ? nace2Object.explanation : false}
            title={t('companyResult.summary.activities')}
            value={nace2Object?.code || '-'}
            icon="activities"
          />
          <SummarySmallBlock
            value={
              summaryInformationState?.income ? formatCurrency(summaryInformationState.income) : t('global.noEntries')
            }
            title={t('companyResult.summary.income')}
            icon="income"
          />
          <SummarySmallBlock
            blur
            explanation={
              summaryInformationState?.employeeNumber
                ? t('companyResult.summary.employeesNumber.explanation', {
                    date: summaryInformationState?.employeeNumberDate
                      ? formatDate(summaryInformationState?.employeeNumberDate, DATE_FORMAT_LONG)
                      : '-',
                  })
                : false
            }
            title={t('companyResult.summary.employeesNumber')}
            value={summaryInformationState?.employeeNumber || t('global.noEntries')}
            icon="employeeNumber"
          />
          <SummarySmallBlock
            blur
            explanation={t('companyResult.summary.averageWages.explanation', {
              averageSalaryDate: summaryInformationState?.averageWageDate
                ? formatDate(summaryInformationState?.averageWageDate, DATE_FORMAT_LONG)
                : '-',
              averageSalaryWomen: summaryInformationState?.averageFemale
                ? formatCurrency(summaryInformationState?.averageFemale)
                : t('global.noCurrency'),
              averageSalaryMen: summaryInformationState?.averageMale
                ? formatCurrency(summaryInformationState?.averageMale)
                : t('global.noCurrency'),
            })}
            title={t('companyResult.summary.averageWages')}
            value={
              summaryInformationState?.averageWage
                ? formatCurrency(summaryInformationState.averageWage)
                : t('global.noEntries')
            }
            icon="averageSalary"
          />
          <SummarySmallBlock
            blur
            explanation={t('companyResult.summary.reportNumbers.value', {
              reports1: summaryInformationState?.companyStatistics?.lastMonth,
              reports2: summaryInformationState?.companyStatistics?.lastQuarter,
              reports3: summaryInformationState?.companyStatistics?.lastHalfYear,
            })}
            title={t('companyResult.summary.reportNumbers')}
            value={summaryInformationState?.companyStatistics?.lastHalfYear || t('global.noEntries')}
            icon="reportNumbers"
          />
          <SummarySmallBlock
            blur
            title={t('companyResult.summary.socialSecurityDebts')}
            value={
              summaryInformationState?.sodraDebts?.amount
                ? formatCurrency(summaryInformationState.sodraDebts.amount)
                : t('companyResult.summary.socialSecurityDebts.none')
            }
            explanation={
              summaryInformationState?.sodraDebts?.amount ? (
                <Trans
                  i18nKey="companyResult.summary.socialSecurityDebts.value"
                  values={{
                    debtAmount: formatCurrency(summaryInformationState?.sodraDebts?.amount),
                    deferredAmount: summaryInformationState?.sodraDebts?.deffAmount
                      ? formatCurrency(summaryInformationState.sodraDebts.deffAmount)
                      : 0,
                    date: summaryInformationState?.sodraDebts?.latestDate
                      ? formatDate(summaryInformationState?.sodraDebts?.latestDate, DATE_FORMAT_LONG)
                      : '-',
                  }}
                />
              ) : null
            }
            negativeClass={
              summaryInformationState?.sodraDebts?.amount &&
              summaryInformationState?.sodraDebts?.deffAmount < summaryInformationState?.sodraDebts?.amount
            }
            icon="sodraDebts"
          />
          <SummarySmallBlock
            blur
            explanation={
              summaryInformationState.paidDebts?.total
                ? t('professionalReport.summary.paymentHistory.explanation', {
                    amount: summaryInformationState?.paidDebts?.total || '-',
                    date: formatDate(summaryInformationState?.paidDebts?.latestDate),
                    days: summaryInformationState?.paidDebts?.averageDays,
                  })
                : false
            }
            title={t('professionalReport.summary.paymentHistory.title')}
            value={summaryInformationState?.paidDebts?.total || t('global.noEntries')}
            negativeClass={handleNegativeClass(
              summaryInformationState?.paidDebts?.total,
              summaryInformationState?.paidDebts?.latestDate
            )}
            icon="paymentHistory"
          />
          <SummarySmallBlock
            blur
            explanation={
              summaryInformationState?.legalProceeding?.total
                ? t('companyResult.summary.courtHearings.explanation', {
                    totalDefendant: summaryInformationState?.legalProceeding?.totalDefendant || '0',
                    latestDate: summaryInformationState?.legalProceeding?.latestDate
                      ? formatDate(summaryInformationState?.legalProceeding?.latestDate, DATE_FORMAT_LONG)
                      : '-',
                  })
                : false
            }
            title={t('companyResult.summary.courtHearings')}
            value={summaryInformationState?.legalProceeding?.total || '-'}
            icon="legalProceedings"
            negativeClass={handleNegativeClass(
              summaryInformationState?.legalProceeding?.totalDefendant,
              summaryInformationState?.legalProceeding?.latestDateDefendant
            )}
          />
        </Row>
      </div>
    </>
  );
};

export default Summary;
