import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthenticated } from '../../store/entities/user';
import { setCustomPdfValues } from '../../store/entities/professionalReport';
import { getFeatures } from 'store/entities/user/thunks';
import useRedirect from 'hooks/navigation/useRedirect';
import { useLocation } from 'react-router-dom';
import { setUserId } from '@amplitude/analytics-browser';

const useAuthCheck = () => {
  const location = useLocation();
  const isPdfReport = location?.pathname?.includes('/pdf-print');
  const purchaseMessage = location?.pathname?.includes('/report-purchase');

  const dispatch = useDispatch();
  const { hasSession = false, isAuthenticated, session } = useSelector(state => state.user);
  const redirect = useRedirect();

  const isCompanyResultPage = pathname => {
    const companyResultRegex = /^\/search\/company\/\d+\/result$/;
    return companyResultRegex.test(pathname);
  };

  const checkAuth = () => {
    const customPdfValues = localStorage.getItem('customPdfValues');
    if (customPdfValues) {
      dispatch(setCustomPdfValues(JSON.parse(atob(customPdfValues))));
    }
    const token = localStorage.getItem('token');

    const hasAuth = !!(token && hasSession);
    if (hasAuth !== isAuthenticated) {
      dispatch(setIsAuthenticated(hasAuth));
    }

    if (!token && !isPdfReport && !isCompanyResultPage(location.pathname) && !purchaseMessage) {
      redirect(`/${location.search}`);
    }
  };

  const setViewportMeta = content => {
    let viewportMeta = document.querySelector('meta[name="viewport"]');
    viewportMeta.setAttribute('content', content);
  };

  useEffect(() => {
    const handleStorageEvent = event => {
      if (event.key === 'token') {
        checkAuth();
      }
    };
    window.addEventListener('storage', handleStorageEvent);
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  useEffect(() => {
    checkAuth();
    dispatch(getFeatures());
    if (session && !isPdfReport) {
      setViewportMeta('width=992');
      if (session.isPasswordChangeMandatory || !session.isTermsAndConditionsAccepted) {
        setTimeout(() => {
          redirect('/terms-and-conditions');
        }, 1000); // Small delay to ensure state update propagation
      }
      setUserId(session.creditinfoId);
    } else {
      setViewportMeta('width=device-width, initial-scale=1');
    }
  }, [hasSession]);
};

export default useAuthCheck;
