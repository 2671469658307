import { scroller } from 'react-scroll';

export const scrollToSection = (sectionId, customConfig = {}) => {
  scroller.scrollTo(sectionId, {
    duration: 0,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset: -50,
    ...customConfig,
  });
};
