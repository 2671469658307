import React, { useState } from 'react';

import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { BlockHeader } from './BlockHeader';
import { InfoCircleOutlined } from '@ant-design/icons';
import useIsUiHidden from '../../hooks/dom/useIsUiHidden';
import { useSelector } from 'react-redux';

import { ReactComponent as SodraDebts } from 'assets/img/icons/summaryBlock/sodraDebts.svg';
import { ReactComponent as EmployeeNumber } from 'assets/img/icons/summaryBlock/employeeNumber.svg';
import { ReactComponent as CreditLimit } from 'assets/img/icons/summaryBlock/creditLimit.svg';
import { ReactComponent as PaymentHistory } from 'assets/img/icons/summaryBlock/paymentHistory.svg';
import { ReactComponent as AverageSalary } from 'assets/img/icons/summaryBlock/averageSalary.svg';
import { ReactComponent as Income } from 'assets/img/icons/summaryBlock/income.svg';
import { ReactComponent as ActiveDebts } from 'assets/img/icons/summaryBlock/activeDebts.svg';
import { ReactComponent as RegisteredArrests } from 'assets/img/icons/summaryBlock/registeredArrests.svg';
import { ReactComponent as LegalProceedings } from 'assets/img/icons/summaryBlock/legalProceedings.svg';
import { ReactComponent as NegativeInformation } from 'assets/img/icons/summaryBlock/negativeInformation.svg';
import { ReactComponent as NumberOfShareholders } from 'assets/img/icons/summaryBlock/numberOfShareholders.svg';
import { ReactComponent as ReportNumbers } from 'assets/img/icons/summaryBlock/reportNumbers.svg';
import { ReactComponent as LegalStatus } from 'assets/img/icons/summaryBlock/legalStatus.svg';
import { ReactComponent as RegistrationDate } from 'assets/img/icons/summaryBlock/registrationDate.svg';
import { ReactComponent as CompanyCode } from 'assets/img/icons/summaryBlock/companyCode.svg';
import { ReactComponent as VatCode } from 'assets/img/icons/summaryBlock/vatCode.svg';
import { ReactComponent as Activities } from 'assets/img/icons/summaryBlock/activities.svg';

const semiBoldTag = text => {
  return <span style={{ fontWeight: 600 }}>{text}</span>;
};

const CompanyDataCard = ({ title, icon, children, extra, disclaimer, className = '', alignTextLeft = false }) => {
  return (
    <Card
      className={`card-simple card-simple-padding-none space-vertical-sm has-shadow ${className}`}
      style={{ width: '100%' }}
    >
      <Row>
        <Col md={24} lg={24}>
          <BlockHeader icon={icon} title={title} alignTextLeft={alignTextLeft} />
          {extra && <span className="float-right">{extra}</span>}
        </Col>
      </Row>
      {disclaimer && (
        <Row>
          <Col>
            <span>{disclaimer}</span>
          </Col>
        </Row>
      )}
      <Row className="statistic-block statistic-block--company" gutter={[8, 8]}>
        <Col xs={24} md={24}>
          {children}
        </Col>
      </Row>
    </Card>
  );
};

export const CompanyDataRow = ({ type, value, extraClass = null, disclaimer = '' }) =>
  disclaimer ? (
    <Row gutter={[24, 0]} className="company-data-row">
      <Col className={extraClass}>{disclaimer}</Col>
    </Row>
  ) : (
    <Row gutter={[24, 0]} className="company-data-row">
      <Col xs={24} md={10} lg={10} xl={8} xxl={6}>
        {semiBoldTag(type)}
      </Col>
      <Col className={extraClass} xs={24} md={14} lg={14} xl={16} xxl={18}>
        {value}
      </Col>
    </Row>
  );

export const CompanySmallBlock = ({
  title,
  value,
  extraClass = null,
  explanation = false,
  designIcon,
  negativeClass = false,
}) => {
  const isUiHidden = useIsUiHidden();
  const { isAuthenticated } = useSelector(state => state.user);
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => explanation && setHovered(true);
  const handleMouseLeave = () => explanation && setHovered(false);

  return (
    <Card
      className={`card-simple card-simple-padding-none has-shadow ${extraClass} ${negativeClass && 'negative-class'}`}
      style={{ height: '100%', margin: 'auto', padding: 0, position: 'relative', overflow: 'hidden' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {explanation && (!isUiHidden || !isAuthenticated) && (
        <InfoCircleOutlined className="icon--gray" style={{ position: 'absolute', top: 5, right: 5, zIndex: 2 }} />
      )}
      {hovered ? (
        <Row style={{ height: '100%' }}>
          <Col
            span={24}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <div className="title">{explanation}</div>
          </Col>
        </Row>
      ) : (
        <>
          {designIcon && designIcon}
          <Row style={{ height: '100%' }}>
            <Col span={24} style={{ height: '100%', width: '100%' }}>
              <Row style={{ height: '50%' }}>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'center',
                  }}
                >
                  {' '}
                  <div className="value">{value}</div>
                </Col>
              </Row>
              <Row style={{ height: '50%' }}>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center',
                  }}
                >
                  <div className="title">{title}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export const SummarySmallBlock = ({
  title,
  value,
  extraClass = null,
  explanation = false,
  icon,
  negativeClass = false,
  blur = false,
  pdfAlign = false,
}) => {
  const iconMapping = {
    averageSalary: <AverageSalary />,
    creditLimit: <CreditLimit />,
    income: <Income />,
    activeDebts: <ActiveDebts />,
    paymentHistory: <PaymentHistory />,
    registeredArrests: <RegisteredArrests />,
    legalProceedings: <LegalProceedings />,
    negativeInformation: <NegativeInformation />,
    employeeNumber: <EmployeeNumber />,
    sodraDebts: <SodraDebts />,
    numberOfShareholders: <NumberOfShareholders />,
    reportNumbers: <ReportNumbers />,
    legalStatus: <LegalStatus />,
    registrationDate: <RegistrationDate />,
    companyCode: <CompanyCode />,
    vatCode: <VatCode />,
    activities: <Activities />,
  };
  const defaultIcon = <CreditLimit />;
  const { isAuthenticated } = useSelector(state => state.user);
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => explanation && setHovered(true);
  const handleMouseLeave = () => explanation && setHovered(false);

  const Icon = ({ icon, defaultIcon, negativeClass }) => (
    <div className={negativeClass ? 'icon-negative' : ''}>{icon ? iconMapping[icon] : defaultIcon}</div>
  );

  const Explanation = ({ explanation }) => (
    <Row style={{ height: '100%' }}>
      <Col
        span={24}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <div className="title">{explanation}</div>
      </Col>
    </Row>
  );

  return (
    <Col xs={24} sm={pdfAlign ? 8 : 12} md={8} lg={8} xl={6} xxl={6} className={blur ? 'content-blur' : ''}>
      <Card
        className={`card-simple card-simple-padding-none has-shadow ${extraClass} ${negativeClass && 'negative-class'}`}
        style={{ height: '100%', margin: 'auto', padding: 0, position: 'relative', overflow: 'hidden' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {explanation && (!pdfAlign || !isAuthenticated) && (
          <InfoCircleOutlined className="icon--gray" style={{ position: 'absolute', top: 5, right: 5, zIndex: 2 }} />
        )}
        {hovered && !blur ? (
          <Explanation explanation={explanation} />
        ) : (
          <>
            <Row style={{ height: '100%' }}>
              {/* Left Column */}
              <Col
                span={8}
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon icon={icon} defaultIcon={defaultIcon} negativeClass={negativeClass} />
              </Col>
              {/* Right Columns */}
              <Col span={16} style={{ height: '100%', width: '100%' }}>
                <Row style={{ height: '50%' }}>
                  <Col
                    span={24}
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      justifyContent: 'start',
                    }}
                  >
                    <div className="value">{value}</div>
                  </Col>
                </Row>
                <Row style={{ height: '50%' }}>
                  <Col
                    span={24}
                    style={{
                      display: 'flex',
                      alignItems: 'start',
                      justifyContent: 'start',
                    }}
                  >
                    <div className="title">{title}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </Col>
  );
};

const capitalizeFirstLetter = value => value.charAt(0).toUpperCase() + value.slice(1);
const isPdfReportPage = !!window.location.pathname.includes('pdf-print');

export const CompanyDataTruncatedRow = ({ type, value, extraClass = null, children }) => {
  const { t } = useTranslation();
  const MAX_TEXT_LENGTH = 100;
  const [isTextFull, setIsTextFull] = useState(false);
  const truncatedText = !isTextFull && typeof value === 'string' ? `${value?.slice(0, MAX_TEXT_LENGTH)}...` : value;
  const renderText =
    value.length < MAX_TEXT_LENGTH || typeof value !== 'string' ? (
      capitalizeFirstLetter(value)
    ) : (
      <>
        {capitalizeFirstLetter(truncatedText)}
        {!isTextFull ? (
          <a onClick={() => setIsTextFull(true)} style={{ marginLeft: 5, fontWeight: 'bold' }}>
            {t('global.read_more')}
          </a>
        ) : (
          <a onClick={() => setIsTextFull(false)} style={{ marginLeft: 5, fontWeight: 'bold' }}>
            {t('global.show_less')}
          </a>
        )}
      </>
    );

  return (
    <Row gutter={[24, 0]} className="company-data-row">
      <Col xs={24} md={10} lg={10} xl={8} xxl={6}>
        {semiBoldTag(type)}
      </Col>
      <Col className={extraClass} xs={24} md={14} lg={14} xl={16} xxl={18}>
        {isPdfReportPage ? capitalizeFirstLetter(value) : renderText}
      </Col>
    </Row>
  );
};

export const CompanyDataRowTwoColumn = ({ items = [] }) => (
  <Row gutter={[14]} className="company-data-row-double">
    <Col md={24} lg={12}>
      <Row gutter={[8]}>
        <Col xs={24} xl={10}>
          {semiBoldTag(items[0]?.type)}
        </Col>
        <Col xs={24} xl={14}>
          {items[0]?.value}
        </Col>
      </Row>
    </Col>
    <Col md={24} lg={12}>
      <Row gutter={[8]}>
        <Col xs={24} xl={10}>
          {semiBoldTag(items[1]?.type)}
        </Col>
        <Col xs={24} xl={14}>
          {items[1]?.value}
        </Col>
      </Row>
    </Col>
  </Row>
);

export default CompanyDataCard;
