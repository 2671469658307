import React from 'react';
import { Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { buyReportData } from 'store/entities/companySearchResult/selectors/buyReportSelector';

// backBtnId and submitBtnId are needed for Google Analitics
const OrderActions = ({ handleBackButton, handleContinueButton, isNextBtnDisabled, backBtnId, submitBtnId }) => {
  const { t } = useTranslation();
  const { step, completedSteps, stepperIsLoading } = useSelector(buyReportData);

  return (
    <>
      <Row gutter={[16, 0]} className="space-top-lg buy-report-actions">
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 11, order: 1 }}
          md={{ span: 11, order: 1 }}
          lg={{ span: 11, order: 1 }}
          xl={{ span: 11, order: 1 }}
          xxl={{ span: 11, order: 1 }}
          className="justify-content-end"
        >
          <Button className="btn-secondary" onClick={handleBackButton} id={backBtnId}>
            {t('global.back')}
          </Button>
        </Col>
        <Col
          xs={{ span: 0, order: 2 }}
          sm={{ span: 2, order: 2 }}
          md={{ span: 2, order: 2 }}
          lg={{ span: 2, order: 2 }}
          xl={{ span: 2, order: 2 }}
          xxl={{ span: 2, order: 2 }}
        ></Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 11, order: 3 }}
          md={{ span: 11, order: 3 }}
          lg={{ span: 11, order: 3 }}
          xl={{ span: 11, order: 3 }}
          xxl={{ span: 11, order: 3 }}
          className="justify-content-start"
        >
          <Button
            onClick={handleContinueButton}
            className="btn-success"
            // disabled={(step !== 2 && step !== 3 && !completedSteps.includes(step)) || isNextBtnDisabled}
            loading={stepperIsLoading}
            htmlType="submit"
            id={submitBtnId}
          >
            {/*{t(`global.${step === 1 ? 'buyReport' : step === 3 ? 'pay' : 'continue'}`)}*/}
            {t(`global.${step === 2 ? 'pay' : 'continue'}`)}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default OrderActions;
