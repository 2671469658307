import TableColumnDate from '../../TableColumnDate';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Col, Divider } from 'antd';
import CompanyDataCard, { CompanyDataRow } from '../../CompanyDataCard';
import CollapsibleTable from '../../CollapsibleTable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../../hooks/format/useFormatDate';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

export const ActivitiesRestrictions = ({ activitiesRestrictions, activitiesRestrictionsHistory }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatDate = useFormatDate();

  const historicalJadisColumns = [
    {
      title: t('global.dateFrom'),
      dataIndex: 'dateFrom',
      key: 'sharedholders_historic_dateFrom',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('global.dateTo'),
      dataIndex: 'dateTo',
      key: 'sharedholders_historic_dateTo',
      render: TableColumnDate,
      width: isUiHidden ? 80 : 120,
    },
    {
      title: t('professionalReport.basicData.activitiesRestrictions.history.description'),
      dataIndex: 'description',
      render: (_, record) => {
        return record?.activityName + ' (' + record?.description + ')';
      },
      key: 'description',
    },
  ];

  return (
    <CompanyDataCard
      icon={<CloseCircleOutlined />}
      title={t('professionalReport.basicData.activitiesRestrictions.title')}
    >
      <Col>
        {activitiesRestrictions?.length ? (
          activitiesRestrictions?.map((activityLimitation, i) => {
            return (
              <>
                <CompanyDataRow
                  type={t('professionalReport.basicData.activitiesRestrictions.dateFrom')}
                  value={
                    formatDate(activityLimitation?.dateFrom) ??
                    t('professionalReport.basicData.activitiesRestrictions.noInformation')
                  }
                />
                <CompanyDataRow
                  type={t('professionalReport.basicData.activitiesRestrictions.description')}
                  value={activityLimitation?.activityName + ' (' + activityLimitation?.description + ')'}
                />
                {i + 1 < activitiesRestrictions.length && <Divider />}
              </>
            );
          })
        ) : (
          <h4>{t('professionalReport.basicData.activitiesRestrictions.noRecords')}</h4>
        )}
      </Col>
      {activitiesRestrictionsHistory?.length ? (
        <CollapsibleTable
          className="space-top-md"
          title={t('professionalReport.basicData.shares.historicalInformation.title')}
          data={activitiesRestrictionsHistory}
          columns={historicalJadisColumns}
          bordered
          pagination={false}
        />
      ) : null}
    </CompanyDataCard>
  );
};
