import { Card, Col, Button, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { ReactComponent as BuyReportIcon } from 'assets/img/icons/buyReportIcon.svg';
import { useHistory } from 'react-router-dom';

const BuyReportLink = ({ handleBuyReport }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Col
      xs={{ order: 1, span: 24 }}
      sm={{ order: 1, span: 24 }}
      md={{ order: 1, span: 24 }}
      lg={{ order: 2, span: 12 }}
      xl={{ order: 2, span: 12 }}
      xxl={{ order: 2, span: 12 }}
    >
      <Card
        style={{ height: 'calc(100% - 32px)', background: '#C6E6B1' }}
        className="card-simple card-simple-padding-none space-vertical-sm has-shadow buy-report-card"
      >
        <Row>
          <Col
            xs={{ order: 2, span: 0 }}
            sm={{ order: 1, span: 7 }}
            md={6}
            lg={7}
            xl={6}
            style={{
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <BuyReportIcon />
          </Col>
          <Col xs={{ order: 1, span: 24 }} sm={{ order: 2, span: 17 }} md={18} lg={17} xl={18}>
            <Row gutter={[16, 4]}>
              <Col span={24}>
                <div>
                  <h2 style={{ color: '#0A7A00' }}>
                    <Trans i18nKey="companyResult.text.buyReport.title" components={{ bold: <strong /> }} />
                  </h2>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <span>
                    <Trans i18nKey="companyResult.text.buyReport.text" components={{ bold: <strong /> }} />
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <Row gutter={[16]} style={{ justifyContent: 'space-between' }}>
                  <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => history.push(`/search/company/consultation`)}
                    >
                      {t('companyResult.button.register')}
                    </span>
                  </Col>
                  <Col>
                    <Button className="btn-success" onClick={handleBuyReport}>
                      {t('companyResult.button.buyReport')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default BuyReportLink;
